<template>
  <div
    class="flex items-center justify-center bg-cover bg-center py-10"
    :style="{'background-image': `url(${bgImg})`}"
  >
    <div
      class="flex flex-col items-center gap-5 text-white min-h-screen px-11"
    >
      <Logo class="w-45 md:(mt-20 w-75)" />
      <div class="flex flex-col gap-2 md:gap-5">
        <!--        <img-->
        <!--          :src="img1"-->
        <!--          alt="image"-->
        <!--        >-->
        <div class="w-full rounded-md border-2 p-1 gap-1 md:(rounded-2xl border-6 p-5 gap-5) border-[#147299]  flex flex-col bg-[#051821]">
          <div class="bg-[#147299] flex items-center justify-center text-md py-1 rounded-md md:(text-5xl py-6 mt-2 rounded-xl) text-white ">
            접속가능 주소 안내
          </div>
          <a
            v-for="domain in domains"
            :href="`http://${domain}`"
            target="_blank"
            class="rounded mx-6 md:(rounded-xl text-5xl mx-10 py-6) border border-[#147299]  text-[#147299] flex items-center justify-center bg-[#D9EAFF] hover:underline"
          >
            {{ domain }}
          </a>
          <div class="flex items-center justify-center text-[#147299] text-xs md:text-3xl">
            ※위 도메인 클릭 시 사이트로 이동됩니다.
          </div>
        </div>
      </div>
      <img
        :src="img2"
        alt="image"
      >
      <a
        :href="telegramUrl"
        target="_blank"
        class="w-2/3"
      >
        <img
          :src="telegram"
          alt="image"
        >
      </a>
    </div>
  </div>
</template>
<script setup>
import bgImg from '@/assets/domain/bg.png'
import img1 from '@/assets/domain/img-1.png'
import img2 from '@/assets/domain/img-2.png'
import telegram from '@/assets/domain/telegram.png'
import Logo from "@/components/Logo.vue"
import axios from 'axios'
import { onMounted, ref } from "vue"
import { useDecal } from '@/composables/useDecal.js'

const { telegramUrl } = useDecal()
const domains = ref([])
onMounted(async () => {
  const { data } = await axios.get('api/domains')
  domains.value = data.data.data.map(o => o.domain)
})
</script>
